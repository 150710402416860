import React, { useState, useEffect, useCallback } from "react";
import StudentMenu from "./StudentMenu";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { toast, ToastContainer } from "react-toastify";
import { Form } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";

function ImportStudent() {
  const [AccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID] = useState(localStorage.getItem("ID"));
  const [MemberType] = useState(localStorage.getItem("MemberType"));
  const [SessionID] = useState(localStorage.getItem("DefaultSession"));
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Import Students";
  }, []);

  const fileTypes = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "text/csv",
  ];

  const validateFile = useCallback(() => {
    if (!file) {
      toast.error("Please select a file to upload", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }

    if (!fileTypes.includes(file.type)) {
      toast.error("Please select only Excel or CSV file types", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }

    return true;
  }, [file, fileTypes]);

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file); // Read file as Data URL
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (!validateFile()) return;

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }

    setLoading(true);

    try {
      const base64File = await convertToBase64(file);

      const fileName = file.name;

      const formData = {
        accessKey: AccessKey,
        userId: UserID,
        fileContent: base64File, // Send Base64 file content
        fileName: fileName, // Include the file name
      };
      // Log formData contents
      ////console.log("formData:", formData);

      var api_config = {
        method: "post",
        url: config.base_url + "Student/ImportStudents",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(formData), // Send JSON data
      };

      axios(api_config)
        .then((response) => {
          if (response.data.status_code === 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            // navigate("/students/SearchStudents");
          } else {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          ////console.error(error);
          toast.error("Network Error..", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });

      setValidated(true);
    } catch (error) {
      setLoading(false);
      // ////console.error("Error converting file to Base64", error);
      toast.error("File conversion error.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <>
      <StudentMenu />
      <ToastContainer />
      <div className="container body-content">
        <div className="page-head">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/Dashboard">Dashboard</NavLink>
                    </li>
                    <li className="breadcrumb-item">
                      <NavLink to="/students/StudentDashboard">
                        Students
                      </NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Import Students
                    </li>
                  </ol>
                </nav>
                <div className="page-title">Import Students</div>
              </div>
              <div className="col-md-6">
                <div className="page-right-actions">
                  <NavLink
                    to="/students/SearchStudents"
                    className="button button-white button-icon"
                  >
                    Search Students <i className="ri-search-line"></i>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-content">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body card-form-wrap">
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleSubmit}
                    >
                      <div className="form-wrap">
                        <div className="form-legend">Import Students</div>
                        <div className="row align-items-center">
                          <div className="col-md-6">
                            <h5>Import CSV List of Students</h5>
                            <p>
                              Please make sure that your file follows the
                              template that can be downloaded from the link
                              below.
                            </p>
                            <a
                              className="mt-2 button button-primary w-50"
                              href="https://agsapi.jueducation.com/SampleForImportStudents.xlsx" download
                            >
                              DOWNLOAD TEMPLATE
                            </a>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label htmlFor="formFile" className="form-label">
                                Import File Here
                              </label>
                              <input
                                className="form-control"
                                type="file"
                                onChange={(e) => setFile(e.target.files[0])}
                                id="formFile"
                                accept=".xls,.xlsx,.csv"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3 offset-md-9 text-md-end">
                            <div className="form-group">
                              {loading ? (
                                <Spinner animation="border" role="status" />
                              ) : (
                                <button
                                  type="submit"
                                  className="mt-2 button button-primary w-100"
                                >
                                  IMPORT STUDENTS
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ImportStudent;
