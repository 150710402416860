import React, { useState, useEffect } from "react";
import StudentMenu from "./StudentMenu";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import WingsDropDown from "../Shared/WingsDropDown";
import ClassesDropDown from "../Shared/ClassesDropDown";
import SessionsDropDown from "../Shared/SessionsDropDown";
import SectionDropDown from "../Shared/SectionDropDown";
import BusrouteDropDown from "../Shared/BusrouteDropDown";
import ConcessionDropDown from "../Shared/ConcessionDropDown";
import StudentGroupDropDown from "../Shared/StudentGroupDropDown";
import { toast, ToastContainer } from "react-toastify";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { NavLink, Route } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { scrollToTop } from "../Shared/ScrollOnTop";

function EditStudent(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [UserGroupID, setUserGroupID] = useState(
    localStorage.getItem("User_Group_ID")
  );
  const [WingID, setWingId] = useState(0);
  const [SessionID, setSessioinId] = useState(0);
  const [ClassID, setClassId] = useState(0);
  const [TransportId, setTransportId] = useState(0);
  const [TransportFeeType, setTransportFeeType] = useState("");
  const [SectionID, setSectionID] = useState(0);
  const [ConcessionID, setConcessionId] = useState(0);
  const [ConcessionIDs, setConcessionIDs] = useState([]);
  const [GroupId, setGroupId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [Concessions, setConcessions] = useState([]);

  const [ListofStatusHistory, setListofStatusHistory] = useState("");

  const [BayForm, setBayForm] = useState("");
  const [FormNo, setFormNo] = useState("");
  const [StudentName, setStudentName] = useState("");
  const [DateOfBirth, setDateOfBirth] = useState("");
  const [DateOfJoining, setdateofJoining] = useState("");
  const [FatherName, setFatherName] = useState("");
  const [FatherCNIC, setFatherCNIC] = useState("");
  const [FamilyNo, setFamilyNo] = useState("");
  const [MotherName, setMotherName] = useState("");
  const [Tehsil, setTehsil] = useState("");
  const [District, setDistrict] = useState("");

  const [VaccinationDate, setVaccinationDate] = useState("");
  const [VaccinationStatus, setVaccinationStatus] = useState("");

  const [TemporaryAddress, setTemporaryAddress] = useState("");
  const [PermanentAddress, setPermanentAddress] = useState("");
  const [ContactNumber, setContactNumber] = useState("");
  const [MobileNumber, setMobileNumber] = useState("");
  const [EmailAddress, setEmailAddress] = useState("");
  const [PreviousSchoolInfo, setPreviousSchoolInfo] = useState("");
  const [NOCBoard, setNOCBoard] = useState("");
  const [Remarks, setRemarks] = useState("");
  const [ConcessionRemarks, setConcessionRemarks] = useState("");
  const [RegNo, setRegNo] = useState("");
  const [Gender, setGender] = useState(true);
  let params = useParams();
  const [Student_ID, setStudent_ID] = useState(params.id);
  const [Student, setStudent] = useState({});
  // photo
  const [isUpdated, setIsUpdated] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState("");
  const [cropData, setCropData] = useState("");
  const [cropper, setCropper] = useState();
  const [imageSelected, setImageSelected] = useState(false);
  const [imageCropped, setImageCropped] = useState(false);
  const [imgData, setImageData] = useState("");
  const [listOfLedger, setLedger] = useState([]);
  const [ModifiedTime, setModifiedTime] = useState("");
  const [ListOfSessions, setListOfSessions] = useState([]);
  const [ListOfWings, setListOfWings] = useState([]);
  const [ListOfClasses, setListOfClasses] = useState([]);
  const [ListOFSections, setListOfSections] = useState([]);
  const [ListOfRoutes, setListOfRoutes] = useState([]);
  const [ListOfGroups, setListOfGroups] = useState([]);
  const [ConcessionAmount, setConcessionAmount] = useState(0);
  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const handleShowModal = () => setShowModal(true);

  const handleCloseModal = () => {
    setShowModal(false);
    setImageSelected(false);
    setLoading(false);
  };

  const handleGenderChange = (event) => {
    setGender(event.target.checked);
  };

  const [validated, setValidated] = useState(false);
  const [History_Modified, setHistory_Modified] = useState(false);

  useEffect(() => {
    document.title = "Edit Student Information";
    fetchConcessions();
    fetchSessions();
    fetchWings();
    fetchRoutes();
    fetchStudentGroups();
    setHistory_Modified(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: Student_ID,
      MemberType: MemberType,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Student/GetStudentByID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          //console.log(response.data);
          setStudent(response.data.Student);
          setRegNo(response.data.Student.Registration_No);
          setStudentName(response.data.Student.Student_Name);
          setFatherName(response.data.Student.Father_Name);
          setFatherCNIC(response.data.Student.Father_CNIC);
          setTemporaryAddress(response.data.Student.Temporary_Address);
          setPermanentAddress(response.data.Student.Permanent_Address);
          setFormNo(response.data.Student.Form_No);
          setGender(response.data.Student.Gender);
          setStudent_ID(response.data.Student.Student_ID);
          setDateOfBirth(
            moment(response.data.Student.Date_Of_Birth).format("YYYY-MM-DD")
          );
          setdateofJoining(
            moment(response.data.Student.Date_Of_Joining).format("YYYY-MM-DD")
          );
          setContactNumber(response.data.Student.Contact_Phone);
          setMobileNumber(response.data.Student.Contact_Mobile);
          setEmailAddress(response.data.Student.Contact_Email);
          setBayForm(response.data.Student.Bay_Form_No);
          setVaccinationStatus(response.data.Student.Vaccination_Status);
          setVaccinationDate(response.data.Student.Vaccination_Date);
          setPreviousSchoolInfo(response.data.Student.Previous_School_Info);
          setNOCBoard(response.data.Student.NOC_Board);
          setRemarks(response.data.Student.Remarks);
          setConcessionRemarks(response.data.Student.Concession_Remarks);
          setFormNo(response.data.Student.Form_No);
          setConcessionId(response.data.Student.Concession_Rule_ID);
          setTransportId(response.data.Student.Current_Route_Id);
          setTransportFeeType(response.data.Student.Transport_Fee_Type);
          setGroupId(response.data.Student.Student_Group_Id);
          setVaccinationStatus(response.data.Student.Vaccination_Status);
          setVaccinationDate(response.data.Student.Vaccination_Date);
          setConcessionIDs(
            response.data.ListOfMultiConcessions.map(
              (item) => item.Concession_Rule_ID
            )
          );

          setWingId(response.data.Student.SelectedClass.Wing_Id);
          setSessioinId(response.data.Student.SelectedClass.Session_ID);
          setClassId(response.data.Student.SelectedClass.Class_Id);
          setSectionID(response.data.Student.SelectedSection.Section_ID);

          setListofStatusHistory(response.data.ListofStatusHistory);
          setModifiedTime(response.data.NoofTimesModified);
          setLedger(response.data.ListofStatusHistory);

          setFamilyNo(response.data.Student.FamilyNo);
          setMotherName(response.data.Student.MotherName);
          setTehsil(response.data.Student.Tehsil);
          setDistrict(response.data.Student.District);
          setConcessionAmount(response.data.Student.Concession_Amount);
          setHistory_Modified(false);
        } else {
          setHistory_Modified(false);
        }
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);

  const fetchConcessions = () => {
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      SchoolID: 1,
      pageNo: 1,
      pageSize: 1000,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Concession/GetConcessionRules",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    ////console.log(data);

    axios(api_config)
      .then(function (response) {
        ////console.log(response.data);
        setConcessions(response.data.ListofRecords || []);
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  const fetchSessions = () => {
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      SchoolID: 1,
      pageNo: 1,
      pageSize: 1000,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "AcademicSession/GetAcademicSessions",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    ////console.log(data);

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setListOfSessions(response.data.ListofRecords || []);
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  const fetchWings = () => {
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      SchoolID: 1,
      pageNo: 1,
      pageSize: 1000,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Wings/GetSchoolWings",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    ////console.log(data);

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setListOfWings(response.data.ListofRecords || []);
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  useEffect(() => {
    fetchClasses();
  }, [WingID, SessionID]);

  useEffect(() => {
    fetchSections();
  }, [ClassID]);
  const fetchClasses = () => {
    if (WingID > 0 && SessionID > 0) {
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberType: MemberType,
        Wing_Id: WingID,
        SessionID: SessionID,
        pageNo: 1,
        pageSize: 1000,
      };

      var api_config = {
        method: "post",
        url: config.base_url + "WingClass/GetClassesByWingnSessionID",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      ////console.log(data);

      axios(api_config)
        .then(function (response) {
          //console.log(response.data);
          setListOfClasses(response.data.ListofRecords || []);
        })
        .catch(function (error) {
          //console.log(error);
        });
    }
  };

  const fetchSections = () => {
    if (ClassID > 0) {
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberType: MemberType,
        Class_Id: ClassID,
        pageNo: 1,
        pageSize: 1000,
      };

      var api_config = {
        method: "post",
        url: config.base_url + "Sections/GetAllSections",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      ////console.log(data);

      axios(api_config)
        .then(function (response) {
          //console.log(response.data);
          setListOfSections(response.data.ListofRecords || []);
        })
        .catch(function (error) {
          //console.log(error);
        });
    }
  };

  const fetchRoutes = () => {
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      SchoolID: 1,
      PageNo: 1,
      PageSize: 100,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Busroute/GetBusRoutes",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    ////console.log(data);

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setListOfRoutes(response.data.ListofRecords || []);
      })
      .catch(function (error) {
        //console.log(error);
      });
  };
  const fetchStudentGroups = () => {
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      SchoolID: 1,
      pageNo: 1,
      pageSize: 1000,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Student/GetStudentGroups",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    ////console.log(data);

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setListOfGroups(response.data.ListofRecords || []);
      })
      .catch(function (error) {
        //console.log(error);
      });
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (WingID <= 0) {
      toast.error("Please Select Wing", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (ClassID <= 0) {
      toast.error("Please Select Class", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (SectionID <= 0) {
      toast.error("Please Select Section", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setLoading(true);
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        Registration_No: RegNo,
        Student_Name: StudentName,
        Father_Name: FatherName,
        Father_CNIC: FatherCNIC,
        Temporary_Address: TemporaryAddress,
        Permanent_Address: PermanentAddress,
        Date_Of_Birth: DateOfBirth,
        Contact_Phone: ContactNumber,
        Contact_Mobile: MobileNumber,
        Contact_Email: EmailAddress,
        Bay_Form_No: BayForm,
        Class_ID: ClassID,
        Previous_School_Info: PreviousSchoolInfo,
        NOC_Board: NOCBoard,
        Remarks: Remarks,
        Form_No: FormNo,
        Concession_Rule_ID: ConcessionID,
        Concession_Rule_IDs: ConcessionIDs,
        Current_Route_Id: TransportId,
        Transport_Fee_Type: TransportFeeType,
        Student_Group_Id: GroupId,
        Section_Id: SectionID,
        Vaccination_Status: VaccinationStatus,
        Vaccination_Date: VaccinationDate,
        Gender: Gender,
        Image: cropData,
        Student_ID: Student_ID,
        FamilyNo: FamilyNo,
        MotherName: MotherName,
        Tehsil: Tehsil,
        District: District,
        Concession_Remarks: ConcessionRemarks,
        Concession_Amount: ConcessionAmount,
      };

      ////console.log(data);

      var api_config = {
        method: "post",
        url: config.base_url + "Student/UpdateStudent",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }

          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          toast.error("Network Error..", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }

    setValidated(true);
  };

  const onChange = (e) => {
    e.preventDefault();
    setImageSelected(true);
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      var cropData1 = cropper.getCroppedCanvas().toDataURL();
      setImageCropped(true);

      var cleanerBase64 = cropData1.substring(22);
      setCropData(cleanerBase64);
      setImageData(cropData1);
    }

    handleCloseModal();
  };

  const renderButtons = () => {
    if (imageSelected === true) {
      return (
        <>
          <div className="modal-footer justify-content-center">
            <button
              className="button button-outline-primary me-2"
              onClick={handleCloseModal}
            >
              Close
            </button>
            <button className="button button-primary" onClick={getCropData}>
              Save Photo
            </button>
          </div>
        </>
      );
    } else if (imageSelected === false && isUpdated === true) {
      return (
        <>
          <div className="modal-footer justify-content-center">
            <button
              className="button button-outline-primary me-2"
              onClick={handleCloseModal}
            >
              Close
            </button>
          </div>
        </>
      );
    }
  };
  const renderLedger = () => {
    if (listOfLedger.length > 0) {
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.Serial_No}</td>
            <td>{item.Student_Name}</td>
            <td>{item.Status_Type_Name}</td>
            <td>{moment(item.Dated).format("DD/MM/YYYY")}</td>
            <td>{item.Modified_By}</td>
            <td>{item.Remarks}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="8">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  const ListOfTransportFeeType = [
    { id: 0, name: "Please Select", value: "" },
    { id: 1, name: "PickUp", value: "pickUp" },
    { id: 2, name: "DropOff", value: "dropOff" },
    { id: 3, name: "Pick & Drop", value: "pickDrop" },
  ]
  return (
    <>
      <StudentMenu />
      <ToastContainer />
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Update Profile Photo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isUpdated ? (
            <>
              <div className="text-center">
                <p>Photo Updated Successfully</p>
              </div>
            </>
          ) : (
            <>
              <div className="upload-photo-wrap">
                {imageSelected ? (
                  <Cropper
                    zoomTo={0.5}
                    aspectRatio={8 / 8}
                    preview=".img-preview"
                    src={image}
                    dragMode="move"
                    viewMode={2}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    autoCropArea={1}
                    checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                    onInitialized={(instance) => {
                      setCropper(instance);
                    }}
                    guides={true}
                  />
                ) : (
                  <div className="choose-photo">
                    <label htmlFor="ChoosePhoto">
                      <i className="ri-upload-cloud-line"></i>
                      <span>Browse Photo</span>
                      <input
                        id="ChoosePhoto"
                        accept="image/jpeg,image/png"
                        type="file"
                        onChange={onChange}
                      />
                    </label>
                  </div>
                )}
              </div>
            </>
          )}
        </Modal.Body>

        {renderButtons()}
      </Modal>
      <div className="container body-content">
        <div className="page-head">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/Dashboard">Dashboard</NavLink>
                    </li>
                    <li className="breadcrumb-item">
                      <NavLink to="/students/StudentDashboard">
                        Students
                      </NavLink>
                    </li>

                    <li className="breadcrumb-item active" aria-current="page">
                      Edit / Update Student
                    </li>
                  </ol>
                </nav>
                <div className="page-title">Edit / Update Student</div>
              </div>
              <div className="col-md-6">
                <div className="page-right-actions">
                  <NavLink
                    to="/students/SearchStudents"
                    className="button button-white button-icon"
                  >
                    Search Students
                    <i className="ri-search-line"></i>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-content">
          <div className="container">
            <div className="row">
              <div className="col-md-12 ">
                <div className="card">
                  <div className="card-body card-form-wrap">
                    {loading ? (
                      "Loading..."
                    ) : (
                      <Form
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                      >
                        <div className="form-wrap">
                          <div className="form-legend">Basic Information</div>
                          <div className="row align-items-center">
                            <div className="col-md-9">
                              <div className="row align-items-center g-3 m-0">
                                <label className="col-md-2 col-form-label col-form-label-sm">
                                  Bay Form
                                </label>
                                <div className="col-md-4">
                                  <input
                                    type="number"
                                    numbers-Only
                                    className="form-control"
                                    value={BayForm}
                                    onChange={(e) => setBayForm(e.target.value)}
                                    id=""
                                  />
                                </div>
                                <label className="col-md-2 col-form-label col-form-label-sm">
                                  Form No.
                                </label>
                                <div className="col-md-4">
                                  <input
                                    type="number"
                                    numbers-Only
                                    className="form-control"
                                    value={FormNo}
                                    onChange={(e) => setFormNo(e.target.value)}
                                  />
                                </div>
                                <label className="col-md-2 col-form-label col-form-label-sm">
                                  Student Name
                                </label>
                                <div className="col-md-4">
                                  <input
                                    type="text"
                                    required
                                    className="form-control"
                                    value={StudentName}
                                    onChange={(e) => {
                                      if (
                                        /^[a-zA-Z\s]*$/.test(e.target.value)
                                      ) {
                                        setStudentName(e.target.value);
                                      }
                                    }}
                                  />
                                </div>
                                <label className="col-md-2 col-form-label col-form-label-sm">
                                  Date of Birth
                                </label>
                                <div className="col-md-4">
                                  <input
                                    type="date"
                                    className="form-control"
                                    value={DateOfBirth}
                                    onChange={(e) =>
                                      setDateOfBirth(e.target.value)
                                    }
                                    max={moment()
                                      .subtract(3, "years")
                                      .format(config.date_format_input)}
                                  />
                                </div>
                                <label className="col-md-2 col-form-label col-form-label-sm">
                                  Father Name
                                </label>
                                <div className="col-md-4">
                                  <input
                                    type="text"
                                    required
                                    className="form-control"
                                    value={FatherName}
                                    onChange={(e) => {
                                      if (
                                        /^[a-zA-Z\s]*$/.test(e.target.value)
                                      ) {
                                        setFatherName(e.target.value);
                                      }
                                    }}
                                  />
                                </div>
                                <label className="col-md-2 col-form-label col-form-label-sm">
                                  Father CNIC
                                </label>
                                <div className="col-md-4">
                                  <input
                                    type="number"
                                    minLength={13}
                                    maxLength={13}
                                    numbers-Only
                                    className="form-control"
                                    value={FatherCNIC}
                                    onChange={(e) => {
                                      const inputValue = e.target.value;
                                      if (inputValue.length <= 13) {
                                        setFatherCNIC(inputValue);
                                      }
                                    }}
                                  />
                                </div>

                                <label className="col-md-2 col-form-label col-form-label-sm">
                                  Family No
                                </label>
                                <div className="col-md-4">
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={FamilyNo}
                                    onChange={(e) =>
                                      setFamilyNo(e.target.value)
                                    }
                                  />
                                </div>

                                <label className="col-md-2 col-form-label col-form-label-sm">
                                  Mother Name
                                </label>
                                <div className="col-md-4">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={MotherName}
                                    onChange={(e) => {
                                      if (
                                        /^[a-zA-Z\s]*$/.test(e.target.value)
                                      ) {
                                        setMotherName(e.target.value);
                                      }
                                    }}
                                  />
                                </div>

                                <label className="col-md-2 col-form-label col-form-label-sm">
                                  Gender
                                </label>
                                <div className="col-md-9">
                                  <div className="mt-1">
                                    <div className="form-check form-check-inline">
                                      <label className="form-check-label">
                                        <input
                                          type="radio"
                                          name="gender"
                                          value="male"
                                          checked={Gender === true}
                                          onChange={() => setGender(true)}
                                        />
                                        Male
                                      </label>
                                    </div>
                                    <div className="form-check form-check-inline me-0">
                                      <label className="form-check-label">
                                        <input
                                          type="radio"
                                          name="gender"
                                          value="female"
                                          checked={Gender === false}
                                          onChange={() => setGender(false)}
                                        />
                                        Female
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3 text-center">
                              {imgData == "" ? (
                                <img
                                  className="mb-3"
                                  src={
                                    RegNo != ""
                                      ? config.StudentImagePath + RegNo + ".jpg"
                                      : config.StudentImagePath +
                                      Student_ID +
                                      ".jpg"
                                  }
                                  style={{ width: "150px" }}
                                />
                              ) : (
                                <img
                                  className="mb-3"
                                  src={imgData}
                                  style={{ width: "150px" }}
                                />
                              )}
                              <button
                                type="button"
                                onClick={handleShowModal}
                                className="button  button-small"
                              >
                                Choose Photo
                              </button>
                            </div>
                          </div>
                          <div className="row align-items-center mt-0">
                            <label className="col-md-2 col-form-label col-form-label-sm">
                              Vaccination Status
                            </label>
                            <div className="col-md-4">
                              <select
                                className="form-select"
                                value={VaccinationStatus}
                                defaultValue={Student.Vaccination_Status}
                                onChange={(e) =>
                                  setVaccinationStatus(e.target.value)
                                }
                              >
                                <option value="" disabled selected>
                                  Please Select
                                </option>
                                <option value="Not Vaccinated">
                                  Not Vaccinated
                                </option>
                                <option value="Partially Vaccinated">
                                  Partially Vaccinated
                                </option>
                                <option value="Fully Vaccinated">
                                  Fully Vaccinated
                                </option>
                              </select>
                            </div>
                            <label className="col-md-2 col-form-label col-form-label-sm">
                              Vaccination Date
                            </label>
                            <div className="col-md-4">
                              <input
                                type="date"
                                className="form-control"
                                value={VaccinationDate}
                                onChange={(e) =>
                                  setVaccinationDate(e.target.value)
                                }
                                id=""
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-wrap">
                          <div className="form-legend">Contact Information</div>
                          <div className="row">
                            <label className="col-md-3 col-form-label col-form-label-sm">
                              Temporary Address
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                className="form-control"
                                value={TemporaryAddress}
                                onChange={(e) =>
                                  setTemporaryAddress(e.target.value)
                                }
                                id=""
                              />
                            </div>
                          </div>
                          <div className="row">
                            <label className="col-md-3 col-form-label col-form-label-sm">
                              Permanent Address
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                className="form-control"
                                value={PermanentAddress}
                                onChange={(e) =>
                                  setPermanentAddress(e.target.value)
                                }
                                id=""
                              />
                            </div>
                          </div>
                          <div className="row">
                            <label className="col-md-3 col-form-label col-form-label-sm">
                              District
                            </label>
                            <div className="col-md-3">
                              <input
                                type="text"
                                className="form-control"
                                value={District}
                                onChange={(e) => setDistrict(e.target.value)}
                                id=""
                              />
                            </div>
                            <label className="col-md-3 col-form-label col-form-label-sm">
                              Tehsil
                            </label>
                            <div className="col-md-3">
                              <input
                                type="text"
                                className="form-control"
                                value={Tehsil}
                                onChange={(e) => setTehsil(e.target.value)}
                                id=""
                              />
                            </div>
                          </div>

                          <div className="row">
                            <label className="col-md-3 col-form-label col-form-label-sm">
                              Contact Number
                            </label>
                            <div className="col-md-3">
                              <input
                                type="text"
                                className="form-control"
                                value={ContactNumber}
                                onChange={(e) =>
                                  setContactNumber(e.target.value)
                                }
                                id=""
                              />
                            </div>
                            <label className="col-md-3 col-form-label col-form-label-sm">
                              Mobile Number
                            </label>
                            <div className="col-md-3">
                              <input
                                type="text"
                                className="form-control"
                                value={MobileNumber}
                                onChange={(e) =>
                                  setMobileNumber(e.target.value)
                                }
                                id=""
                              />
                            </div>
                          </div>

                          <div className="row">
                            <label className="col-md-3 col-form-label col-form-label-sm">
                              Email Address
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                className="form-control"
                                value={EmailAddress}
                                onChange={(e) =>
                                  setEmailAddress(e.target.value)
                                }
                                id=""
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-wrap">
                          <div className="form-legend">
                            Session / Wing / Class / Section
                          </div>
                          <div className="row gy-3">
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Choose Session</label>
                                <div
                                  className="card"
                                  style={{ borderRadius: 0 }}
                                >
                                  <div
                                    className="card-body table-wrap"
                                    style={{
                                      height: "200px",
                                      backgroundColor: "#FAFAFA",
                                      overflowY: "auto",
                                    }}
                                  >
                                    <table className="table table-theme table-borderless">
                                      <tbody>
                                        {ListOfSessions.map((item, index) => {
                                          return (
                                            <tr key={index}>
                                              <td
                                                style={{
                                                  padding: 0,
                                                }}
                                              >
                                                <input
                                                  type="radio"
                                                  value={SessionID}
                                                  checked={SessionID}
                                                  onChange={(e) => {
                                                    setSessioinId(e.target.value);
                                                    setSectionID(0);
                                                    setClassId(0);
                                                  }}
                                                />
                                              </td>
                                              <td
                                                style={{
                                                  padding: 0
                                                }}
                                              >
                                                {item.Session_Name}
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Choose Wing</label>
                                <div
                                  className="card"
                                  style={{ borderRadius: 0 }}
                                >
                                  <div
                                    className="card-body table-wrap"
                                    style={{
                                      height: "200px",
                                      backgroundColor: "#FAFAFA",
                                      overflowY: "auto",
                                    }}
                                  >
                                    <table className="table table-theme table-borderless">
                                      <tbody>
                                        {ListOfWings.map((item, index) => {
                                          return (
                                            <tr key={index}>
                                              <td
                                                style={{
                                                  padding: 0,
                                                }}
                                              >
                                                <input
                                                  type="radio"
                                                  value={item.Wing_ID}
                                                  checked={item.Wing_ID == WingID}
                                                  onChange={(e) => {
                                                    setWingId(e.target.value);
                                                    setSectionID(0);
                                                    setClassId(0);
                                                  }}
                                                />
                                              </td>
                                              <td
                                                style={{
                                                  paddingTop: 0,
                                                  paddingBottom: 0,
                                                }}
                                              >
                                                {item.Wing_Name}
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Select Class:</label>
                                <div
                                  className="card"
                                  style={{ borderRadius: 0 }}
                                >
                                  <div
                                    className="card-body table-wrap"
                                    style={{
                                      height: "200px",
                                      backgroundColor: "#FAFAFA",
                                      overflowY: "auto",
                                    }}
                                  >
                                    <table className="table table-theme table-borderless">
                                      <tbody>
                                        {ListOfClasses.map((item, index) => {
                                          return (
                                            <tr key={index}>
                                              <td
                                                style={{
                                                  padding: 0,
                                                }}
                                              >
                                                <input
                                                  type="radio"
                                                  value={item.Class_Id}
                                                  checked={item.Class_Id == ClassID}
                                                  onChange={(e) => {
                                                    setClassId(e.target.value);
                                                  }}
                                                />
                                              </td>
                                              <td
                                                style={{
                                                  padding: 0
                                                }}
                                              >
                                                {item.Class_Name}
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="form-group">
                                <label>Select Section</label>
                                <div
                                  className="card"
                                  style={{ borderRadius: 0 }}
                                >
                                  <div
                                    className="card-body table-wrap"
                                    style={{
                                      height: "200px",
                                      backgroundColor: "#FAFAFA",
                                      overflowY: "auto",
                                    }}
                                  >
                                    <table className="table table-theme table-borderless">
                                      <tbody>
                                        {ListOFSections.map((item, index) => {
                                          return (
                                            <tr key={index}>
                                              <td
                                                style={{
                                                  padding: 0,
                                                  maxWidth: "4px"
                                                }}
                                              >
                                                <input
                                                  type="radio"
                                                  value={item.Section_ID}
                                                  checked={item.Section_ID == SectionID}
                                                  onChange={(e) => {
                                                    setSectionID(e.target.value);
                                                  }}
                                                />
                                              </td>
                                              <td
                                                style={{
                                                  padding: 0
                                                }}
                                              >
                                                {item.Section_Name}
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {
                          UserGroupID == 1
                            ?

                            <div className="form-wrap">
                              <div className="form-legend">
                                Transport / Concessions / Group
                              </div>
                              <div className="row gy-3">
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label>Transport Facility</label>
                                    <div
                                      className="card"
                                      style={{ borderRadius: 0 }}
                                    >
                                      <div
                                        className="card-body table-wrap"
                                        style={{
                                          height: "200px",
                                          backgroundColor: "#FAFAFA",
                                          overflowY: "auto",
                                        }}
                                      >
                                        <table className="table table-theme table-borderless">
                                          <tbody>
                                            {[
                                              { Root_ID: 0, Area_Name: "Please Select" },
                                              ...ListOfRoutes
                                            ].map((item, index) => {
                                              return (
                                                <tr key={index}>
                                                  <td
                                                    style={{
                                                      paddingTop: 0,
                                                      paddingBottom: 0
                                                    }}
                                                  >
                                                    <input
                                                      type="radio"
                                                      value={item.Root_ID}
                                                      checked={item.Root_ID == TransportId}
                                                      onChange={(e) => {
                                                        setTransportId(e.target.value);
                                                      }}
                                                    />
                                                  </td>
                                                  <td
                                                    style={{
                                                      padding: 0
                                                    }}
                                                  >
                                                    {item.Area_Name}
                                                  </td>
                                                </tr>
                                              );
                                            })}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="form-group">
                                    <label>Transport Fee Type</label>
                                    <div
                                      className="card"
                                      style={{ borderRadius: 0 }}
                                    >
                                      <div
                                        className="card-body table-wrap"
                                        style={{
                                          height: "200px",
                                          backgroundColor: "#FAFAFA",
                                          overflowY: "auto",
                                        }}
                                      >
                                        <table className="table table-theme table-borderless">
                                          <tbody>
                                            {ListOfTransportFeeType.map((item, index) => {
                                              return (
                                                <tr key={index}>
                                                  <td
                                                    style={{
                                                      padding: 0,
                                                    }}
                                                  >
                                                    <input
                                                      type="radio"
                                                      value={item.value}
                                                      checked={item.value == TransportFeeType}
                                                      onChange={(e) => {
                                                        setTransportFeeType(e.target.value);
                                                      }}
                                                    />
                                                  </td>
                                                  <td
                                                    style={{
                                                      padding: 0
                                                    }}
                                                  >
                                                    {item.name}
                                                  </td>
                                                </tr>
                                              );
                                            })}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label>Select Concessions</label>
                                    <div
                                      className="card"
                                      style={{ borderRadius: 0 }}
                                    >
                                      <div
                                        className="card-body table-wrap"
                                        style={{
                                          height: "200px",
                                          backgroundColor: "#FAFAFA",
                                          overflowY: "auto",
                                        }}
                                      >
                                        <table className="table table-theme table-borderless">
                                          <tbody>
                                            {Concessions.map((item, index) => {
                                              return (
                                                <tr key={index}>
                                                  <td
                                                    style={{
                                                      paddingTop: 0,
                                                      paddingBottom: 0,
                                                    }}
                                                  >
                                                    <input
                                                      type="checkbox"
                                                      value={
                                                        item.Concession_Rule_ID
                                                      }
                                                      checked={ConcessionIDs.includes(
                                                        item.Concession_Rule_ID
                                                      )}
                                                      onChange={(e) => {
                                                        if (e.target.checked) {
                                                          setConcessionIDs([
                                                            ...ConcessionIDs,
                                                            item.Concession_Rule_ID,
                                                          ]);
                                                        } else {
                                                          setConcessionIDs(
                                                            ConcessionIDs.filter(
                                                              (id) =>
                                                                id !==
                                                                item.Concession_Rule_ID
                                                            )
                                                          );
                                                        }
                                                      }}
                                                    />
                                                  </td>
                                                  <td
                                                    style={{
                                                      paddingTop: 0,
                                                      paddingBottom: 0,
                                                    }}
                                                  >
                                                    {item.Concession_Rule_Name}
                                                  </td>
                                                </tr>
                                              );
                                            })}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="form-group">
                                    <label>Select Group</label>
                                    <div
                                      className="card"
                                      style={{ borderRadius: 0 }}
                                    >
                                      <div
                                        className="card-body table-wrap"
                                        style={{
                                          height: "200px",
                                          backgroundColor: "#FAFAFA",
                                          overflowY: "auto",
                                        }}
                                      >
                                        <table className="table table-theme table-borderless">
                                          <tbody>
                                            {ListOfGroups.map((item, index) => {
                                              return (
                                                <tr key={index}>
                                                  <td
                                                    style={{
                                                      padding: 0,
                                                    }}
                                                  >
                                                    <input
                                                      type="radio"
                                                      value={item.Group_ID}
                                                      checked={item.Group_ID == GroupId}
                                                      onChange={(e) => {
                                                        setGroupId(e.target.value);
                                                      }}
                                                    />
                                                  </td>
                                                  <td
                                                    style={{
                                                      paddingTop: 0,
                                                      paddingBottom: 0
                                                    }}
                                                  >
                                                    {item.Group_Name}
                                                  </td>
                                                </tr>
                                              );
                                            })}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row align-items-center gy-3">
                                <label className="col-md-3 col-form-label col-form-label-sm">
                                  Concession Remarks
                                </label>
                                <div className="col-md-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={ConcessionRemarks}
                                    onChange={(e) =>
                                      setConcessionRemarks(e.target.value)
                                    }
                                  />
                                </div>
                                <label className="col-md-3 col-form-label col-form-label-sm">
                                  Concession Amount
                                </label>
                                <div className="col-md-4 col-sm-8">
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={ConcessionAmount}
                                    onChange={(e) =>
                                      setConcessionAmount(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            :
                            ""
                        }
                        <div className="form-wrap">
                          <div className="form-legend">Other Details</div>
                          <div className="row align-items-center gy-3">
                            <label className="col-md-3 col-form-label col-form-label-sm">
                              Previous School Information
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                className="form-control"
                                value={PreviousSchoolInfo}
                                onChange={(e) =>
                                  setPreviousSchoolInfo(e.target.value)
                                }
                              />
                            </div>

                            <label className="col-md-3 col-form-label col-form-label-sm">
                              NOC Board Information
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                className="form-control"
                                value={NOCBoard}
                                onChange={(e) => setNOCBoard(e.target.value)}
                              />
                            </div>

                            <label className="col-md-3 col-form-label col-form-label-sm">
                              Remarks
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                className="form-control"
                                value={Remarks}
                                onChange={(e) => setRemarks(e.target.value)}
                              />
                            </div>
                            <label className="col-md-3 col-form-label col-form-label-sm">
                              Registration Number
                            </label>
                            <div className="col-md-4">
                              <input
                                type="number"
                                className="form-control"
                                value={RegNo}
                                onChange={(e) => setRegNo(e.target.value)}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-4 offset-md-5 text-md-end">
                              <div className="form-group ">
                                {History_Modified ? (
                                  <Spinner
                                    animation="border"
                                    role="status"
                                  ></Spinner>
                                ) : (
                                  <NavLink
                                    to={`/students/StudentHistory/${Student_ID}`}
                                    className="mt-2 button button-primary w-100 "
                                  >
                                    Record History Modified {ModifiedTime}{" "}
                                    Time(s)
                                  </NavLink>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3 text-md-end">
                              <div className="form-group ">
                                {loading ? (
                                  <Spinner
                                    animation="border"
                                    role="status"
                                  ></Spinner>
                                ) : (
                                  <button
                                    type="submit"
                                    value="Save"
                                    className="mt-2 button button-primary w-100 "
                                  >
                                    UPDATE STUDENT
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row mt-2">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <table className="table table-theme">
                      <thead>
                        <tr>
                          <th colSpan={6}>Students Status History Details :</th>
                        </tr>
                        <tr>
                          <th>Serial No</th>
                          <th>Student Name</th>
                          <th>Status Details</th>
                          <th>Dated</th>
                          <th>Modified By</th>
                          <th>Remarks</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <tr>
                            <td className="text-center" colSpan="12">
                              <Spinner
                                animation="border"
                                role="status"
                              ></Spinner>
                            </td>
                          </tr>
                        ) : (
                          <>{renderLedger()}</>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditStudent;
