import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import SessionsDropDown from "../Shared/SessionsDropDown";
import WingsDropDown from "../Shared/WingsDropDown";
import ClassesDropDown from "../Shared/ClassesDropDown";
import SectionDropDown from "../Shared/SectionDropDown";
import StudentGroupDropDown from "../Shared/StudentGroupDropDown";
import StudentStatusDropDown from "../Shared/StudentStatusDropDown";
import StudentMenu from "./StudentMenu";
import { toast, ToastContainer } from "react-toastify";
import { Link, NavLink } from "react-router-dom";
import moment from "moment";
import Pagination from "../General/Pagination";
import Dropdown from "react-bootstrap/Dropdown";
import { Form, PageItem } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import BioDataForms from "../Shared/BioDataForms";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { scrollToTop } from "../Shared/ScrollOnTop";
import csvDownload from "json-to-csv-export";

function SearchStudents(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  //const [DefaultSession, setDefaultSession] = useState(localStorage.getItem("DefaultSession"));

  const [showFilters, setShowFilters] = useState(true);

  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [listOfLedgerforcvs, setLedgerforcvs] = useState([]);

  const [validated, setValidated] = useState(false);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  // model
  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [Student_ID, setStudent_ID] = useState();
  const [registration_no, setregistration_no] = useState();

  const [WingId, setWingId] = useState(0);
  const [SessionId, setSessioinId] = useState(
    localStorage.getItem("DefaultSession")
  );
  const [ClassId, setClassId] = useState(0);
  const [SectionID, setSectionID] = useState(0);
  const [GroupId, setGroupId] = useState(0);
  const [StudentStatusType, setStudentStatusType] = useState(0);
  const [isUpdated, setIsUpdated] = useState(false);

  const [Registration_No, SetRegistrationNo] = useState("");
  const [FamilyNo, SetFamilyNo] = useState("");
  const [StudentID, setStudentID] = useState(0);
  const [Keywords, setKeywords] = useState("");
  const [Status_Type_Id, SetStatusTypeId] = useState(0);
  const [OrderBy, setOrderBy] = useState(0);

  const [selectedStudents, setselectedStudents] = useState([]);
  const [loaddata, setLoaddata] = useState(false);

  // variables for promote section
  const [promoteSessionID, setPromoteSessionID] = useState(0);
  const [promoteWingID, setpromoteWingID] = useState(0);
  const [promoteClassID, setPromoteClassID] = useState(0);
  const [promoteSectionID, setPromoteSectionID] = useState(0);
  const [validatedPromote, setValidatedPromote] = useState(false);

  useEffect(() => {
    document.title = "Search Students";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: pageSize,
      SessionID: SessionId,
      Wing_Id: WingId,
      Class_Id: ClassId,
      Section_Id: SectionID,
      Registration_No: Registration_No,
      FamilyNo: FamilyNo,
      Student_ID: StudentID,
      Keywords: Keywords,
      Status_Type_Id: Status_Type_Id,
      Group_Id: GroupId,
      OrderBy: OrderBy,
    };

    ////console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Student/SearchStudents",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //     ////console.log(response.data);
        setLedger(response.data.ListofRecords || []);
        //console.log(response.data);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);
      })
      .catch(function (error) {
        ////console.log(error);
      });
  }, [pageNo, pageSize, SessionId, WingId, ClassId, SectionID]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedItems(listOfLedger.map((item) => item.Student_ID));
    } else {
      setSelectedItems([]);
    }
  };

  const toggleSelectItem = (itemId) => {
    if (selectedItems.includes(itemId)) {
      setSelectedItems(selectedItems.filter((id) => id !== itemId));
    } else {
      setSelectedItems([...selectedItems, itemId]);
    }
  };

  const renderLedger = () => {
    if (listOfLedger.length > 0) {
      return listOfLedger.map((item, index) => {
        return (
          <>
            <tr key={index}>
              {/* <td>
                <div className="form-check">
                  <input
                    key={item.Student_ID}
                    data-type-id={item.Student_ID}
                    className="form-check-input checkStudent"
                    type="checkbox"
                    value={item.Student_ID}
                  />
                </div>
              </td> */}
              <td>
                <div className="form-check">
                  <input
                    key={item.Student_ID}
                    data-type-id={item.Student_ID}
                    className="form-check-input checkStudent"
                    type="checkbox"
                    value={item.Student_ID}
                    checked={selectedItems.includes(item.Student_ID)}
                    onChange={() => toggleSelectItem(item.Student_ID)}
                  />
                </div>
              </td>
              <td
                className="text-nowrap"
                style={{ cursor: "pointer" }}
                onClick={(e) => getstudent_ID(item.Student_ID)}
              >
                <Link>{item.Registration_No}</Link>
              </td>
              <td className="">{item.Student_Name}</td>
              <td className="">{item.Father_Name}</td>
              <td>{moment(item.Date_Of_Birth).format("DD/MM/YYYY")}</td>
              <td>{moment(item.Date_Of_Joining).format("DD/MM/YYYY")}</td>
              <td>{item.FamilyNo}</td>
              <td>{item.SelectedClass.SelectedWing.Wing_Name}</td>
              <td>{item.SelectedClass.Class_Name}</td>
              <td>{item.SelectedSection.Section_Name}</td>
              <td>
                <div className="status">
                  {item.Concession_Rule_ID > 1 && (
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={<Tooltip>Concession Applied</Tooltip>}
                    >
                      <span className="green">
                        <i className="ri-percent-fill"></i>
                      </span>
                    </OverlayTrigger>
                  )}
                  {item.Current_Route_Id > 0 && (
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={<Tooltip>Transport</Tooltip>}
                    >
                      <span className="red">
                        <i className="ri-bus-2-fill"></i>
                      </span>
                    </OverlayTrigger>
                  )}
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip>Normal Status</Tooltip>}
                  >
                    <span className="blue">
                      <i className="ri-emotion-normal-line"></i>
                    </span>
                  </OverlayTrigger>
                </div>
              </td>
              <td>
                <ul className="inline-action" >
                  <li>
                    <NavLink
                      to={"/Students/EditStudent/" + item.Student_ID}
                      className=" green"
                    >
                      <i className="ri-edit-line"></i>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      target="_blank"
                      to={
                        "/Students/PrintVoucher/?Class_Id=0&ConcessionRuleID=0&OrderBy=Section_Name&Section_Id=0&SessionID=0&Wing_Id=0&Description=&AdvanceMonths=0&ShowHolidayImage=false&noRedCresent=false&amount=0&StudentType=Normal&Registration_No=" +
                        item.Registration_No
                      }
                      className="blue"
                    >
                      <i className="ri-printer-line"></i>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/Students/StudentLedger/" + item.Student_ID}
                      className="red"
                    >
                      <i className="ri-pages-line"></i>
                    </NavLink>
                  </li>
                </ul>
              </td>
            </tr>
          </>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="12">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  const handleSubmit = (event) => {
    //////console.log(event);
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setLoading(true);
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberType: MemberType,
        pageNo: pageNo,
        pageSize: pageSize,
        SessionID: SessionId,
        Wing_Id: WingId,
        Class_Id: ClassId,
        Section_Id: SectionID,
        Registration_No: Registration_No,
        FamilyNo: FamilyNo,
        Student_ID: StudentID,
        Keywords: Keywords,
        Status_Type_Id: Status_Type_Id,
        Group_Id: GroupId,
        OrderBy: OrderBy,
      };

      ////console.log(data);

      var api_config = {
        method: "post",
        url: config.base_url + "Student/SearchStudents",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          //  ////console.log(response.data);
          setLedger(response.data.ListofRecords || []);
          setLoading(false);
          setTotalRecords(response.data.totalRecords);
        })
        .catch(function (error) {
          ////console.log(error);
        });
    }

    setValidated(true);
  };

  const handleSubmitForCvs = (event) => {
    ////console.log(event);

    setLoaddata(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: 10000,
      SessionID: SessionId,
      Wing_Id: WingId,
      Class_Id: ClassId,
      Section_Id: SectionID,
      Registration_No: Registration_No,
      FamilyNo: FamilyNo,
      Student_ID: StudentID,
      Keywords: Keywords,
      Status_Type_Id: Status_Type_Id,
      Group_Id: GroupId,
      OrderBy: OrderBy,
    };

    ////console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Student/SearchStudents",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        ////console.log(response.data);
        setLedgerforcvs(response.data.ListofRecords);
        let data = response.data.ListofRecords;
        let count = 0;
        const listOfLedgerNew = data.map(({ ...rest }) => ({
          Serial_No: count++ + pageSize * (pageNo - 1),
          Reg_No: rest.Registration_No,
          Name: rest.Student_Name,
          Father_Name: rest.Father_Name,
          DOB: moment(rest.Date_Of_Birth).format("DD/MM/YYYY"),
          DOJ: moment(rest.Date_Of_Joining).format("DD/MM/YYYY"),
          Mobile: `=T("${rest.Contact_Mobile}")`,
          Wing: rest.SelectedClass.SelectedWing.Wing_Name,
          Class: rest.SelectedClass.Class_Name,
          Section: rest.SelectedSection.Section_Name,
        }));
        //   ////console.log(listOfLedgerNew);

        const dataToConvert = {
          data: listOfLedgerNew,
          filename: "SeachStudent",
          delimiter: ",",
          headers: [
            "Serial_No",
            "Reg_No",
            "Name",
            "Father_Name",
            "DOB",
            "DOJ",
            "Mobile",
            "Wing",
            "Class",
            "Section",
          ],
        };

        csvDownload(dataToConvert);
        setLoaddata(false);
      })
      .catch(function (error) {
        ////console.log(error);
        setLoaddata(false);
      });
  };

  const getstudent_ID = (ID) => {
    setLgShow(true);
    setStudent_ID(ID);
    // setregistration_no(Reg_No)
  };

  const handleClose = () => {
    setLgShow(false);
    setStudent_ID("");
  };

  //handlePromote
  const handlePromote = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      // setLoading(true);

      const inputsStudents = document.querySelectorAll("input.checkStudent");
      const valuesSelected = [];
      inputsStudents.forEach((input) => {
        if (input.checked == true) {
          const value = parseInt(input.value);
          if (value > 0) {
            valuesSelected.push(value);
          }
        }
      });

      ////console.log(valuesSelected);
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberType: MemberType,
        SelectedStudents: valuesSelected,
        SessionID: promoteSessionID,
        Class_Id: promoteClassID,
        Section_Id: promoteSectionID,
      };

      var api_config = {
        method: "post",
        url: config.base_url + "/Student/PromoteStudents",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      //////console.log(data);

      axios(api_config)
        .then(function (response) {
          ////console.log(response.data);
          if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setIsUpdated(true);
          } else {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          setLoading(false);
        })
        .catch(function (error) {
          ////console.log(error);
        });
    }

    setValidatedPromote(true);
  };

  return (
    <>
      <StudentMenu />
      <ToastContainer />
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        {/* <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Student Bio Data
                    </Modal.Title>
                </Modal.Header> */}
        <Modal.Body>
          <i
            className="ri-close-circle-fill text-danger"
            onClick={handleClose} // Close the modal when the close icon is clicked
            style={{
              fontSize: "28px",
              cursor: "pointer",
              position: "absolute",
              right: "8px",
              top: "0",
            }}
          ></i>
          {loading ? (
            <Spinner animation="border" role="status"></Spinner>
          ) : (
            <>
              <BioDataForms StudentID={Student_ID} />
              <Link
                to={`/Setup/PrintBioDataForms/${Student_ID}`}
                target="_blank"
                className="print-icon"
              >
                <span>
                  <i className="ri-printer-line"></i>
                </span>
                Print BioData Forms
              </Link>
            </>
          )}
        </Modal.Body>
      </Modal>
      <div className="container body-content">
        <div className="page-head">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/Dashboard">Dashboard</NavLink>
                    </li>
                    <li className="breadcrumb-item">
                      <NavLink to="/students/StudentDashboard">
                        Students
                      </NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Search Students
                    </li>
                  </ol>
                </nav>
                <div className="page-title">Search Students</div>
              </div>

              <div className="col-md-6">
                <div className="page-right-actions">
                  <NavLink
                    to="/students/AddStudent"
                    className="button button-white button-icon"
                  >
                    Add Students
                    <i className="ri-add-fill"></i>
                  </NavLink>
                  <button
                    onClick={() => {
                      setShowFilters((prevIsActive) => !prevIsActive);
                    }}
                    className="button button-white button-icon"
                  >
                    {showFilters ? "Hide Filters" : "Show Filters"}
                    <i className="ri-filter-3-line"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`collapse ${showFilters ? "show" : ""} `}
          id="collapseFilteres"
        >
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="page-content">
              <div className="container">
                <div className="card mb-2">
                  <div className="card-body p-md-4">
                    <div className="row align-items-center">
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label className="">
                            Select Session:
                          </label>
                          <select
                            className="form-select"
                            onChange={(e) => {
                              setSessioinId(e.target.value);
                              setWingId(0);
                              setClassId(0);
                              setSectionID(0);
                            }}
                            name="SessionID"
                          >
                            <option value="0">---All---</option>
                            <SessionsDropDown selectedSession={SessionId} />
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label>Select Wing:</label>
                          <select
                            name="WingId"
                            onChange={(e) => {
                              setWingId(e.target.value);
                              setClassId(0);
                              setSectionID(0);
                            }}
                            className="form-select"
                          >
                            <option value="0">---All---</option>
                            <WingsDropDown />
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label className="">
                            Select Class:
                          </label>
                          <select
                            className="form-select"
                            onChange={(e) => {
                              setClassId(e.target.value);
                              setSectionID(0);
                            }}
                            name="Class_ID"
                          >
                            <option value="">Please Select</option>
                            {SessionId != 0 && WingId != 0 && (
                              <ClassesDropDown
                                SessionID={SessionId}
                                WingID={WingId}
                              />
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label className="">
                            Select Section:
                          </label>
                          <select
                            className="form-select"
                            onChange={(e) => setSectionID(e.target.value)}
                            name="Section_Id"
                          >
                            <option value="0">Please Select</option>
                            {ClassId !== 0 && (
                              <SectionDropDown ClassID={ClassId} />
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label className="">
                            Registration No:
                          </label>
                          <input
                            type="text"
                            name="Reg_No"
                            onChange={(e) => SetRegistrationNo(e.target.value)}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label className="">
                            Family No:
                          </label>
                          <input
                            type="text"
                            name="Reg_No"
                            onChange={(e) => SetFamilyNo(e.target.value)}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label className="">
                            Student Id:
                          </label>
                          <input
                            type="text"
                            name="Student_ID"
                            onChange={(e) => setStudentID(e.target.value)}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label className="">
                            Keywords:
                          </label>
                          <input
                            type="text"
                            name="Keywords"
                            onChange={(e) => setKeywords(e.target.value)}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label className="">
                            Student Status:
                          </label>
                          <select
                            className="form-select"
                            onChange={(e) => SetStatusTypeId(e.target.value)}
                            name="statustype"
                          >
                            <option value="">---All---</option>
                            {<StudentStatusDropDown />}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label className="">
                            Select Group:
                          </label>
                          <select
                            className="form-select"
                            onChange={(e) => setGroupId(e.target.value)}
                            name="groupName"
                          >
                            <option value="">---All---</option>
                            {<StudentGroupDropDown />}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label className="">
                            Order by:
                          </label>
                          <select
                            name="OrderBy"
                            onChange={(e) => setOrderBy(e.target.value)}
                            className="form-select"
                          >
                            <option Selected Value="Student_Name">
                              Student Name
                            </option>
                            <option Value="Registration_No">
                              Registration No
                            </option>
                            <option Value="Wing_Name">Wing Name</option>
                            <option Value="Class_Name">Class</option>
                            <option Value="Student_ID">Student ID Asc</option>
                            <option Value="Student_ID desc">
                              Student ID Desc
                            </option>
                          </select>
                        </div>
                      </div>
                      {loaddata ? (
                        <div className="col-md-1 text-center">
                          <div>
                            <div>
                              <Spinner
                                className="mt-4"
                                animation="border"
                                role="status"
                              ></Spinner>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="col-md-1 text-center ">
                          <div className="form-group text-end mt-4">
                            <a
                              type="button"
                              onClick={handleSubmitForCvs}
                              className="print-icon"
                            >
                              <span>
                                <i className="ri-file-excel-2-line"></i>
                              </span>
                            </a>
                          </div>
                        </div>
                      )}
                      <div className="col-md-2 text-end ">
                        <div className="form-group text-end">
                          <button
                            type="submit"
                            value="Search"
                            onClick={handleSubmit}
                            className="button button-primary mt-4 w-100"
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
        <div className="page-content">
          <div className="container">
            <div className="card">
              <div className="card-body table-wrap">
                {/* <div className="text-end mb-2 me-3">
                                    <b>{{totalRecords}} &nbsp; Records Found!</b>

                                </div> */}
                <table className="table table-theme">
                  <thead>
                    <tr>
                      <td>
                        <div className="form-check">
                          <input
                            className="form-check-input checkStudent"
                            type="checkbox"
                            value="selectAll"
                            checked={selectAll}
                            onChange={toggleSelectAll}
                          />
                        </div>
                      </td>
                      <th>Reg No</th>
                      <th>Name</th>
                      <th>Father Name</th>
                      <th>DOB</th>
                      <th>DOJ</th>
                      <th>Family No</th>
                      <th>Wing</th>
                      <th>Class</th>
                      <th>Section</th>
                      <th>Tags</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td className="text-center" colSpan="12">
                          <Spinner animation="border" role="status"></Spinner>
                        </td>
                      </tr>
                    ) : (
                      <>{renderLedger()}</>
                    )}
                  </tbody>
                </table>
                {totalRecords > 10 && (
                  <div className="pagination-wrap">
                    <div className="row align-items-center">
                      <div className="col-md-2 form_default">
                        <div className="d-flex align-items-center">
                          <label className="me-2" style={{ fontSize: "15px" }}>
                            Page Size
                          </label>
                          <div className="form-group">
                            <select
                              className="form-select"
                              style={{ width: "100px" }}
                              onChange={(e) => {
                                setPageNo(1);
                                setPageSize(parseInt(e.target.value));
                              }}
                            >
                              <option value="10" selected>
                                10
                              </option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                              <option value="500">500</option>
                              <option value="1000">1000</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <Pagination
                        pageNo={pageNo}
                        pageSize={pageSize}
                        totalRecords={totalRecords}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="container">
            <Form
              noValidate
              validated={validatedPromote}
              onSubmit={handlePromote}
            >
              <div className="card mt-2">
                <div className="card-body p-md-4">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label className="">
                          Select Session:
                        </label>
                        <select
                          className="form-select"
                          onChange={(e) => setPromoteSessionID(e.target.value)}
                          required
                        >
                          <option value="">---All---</option>
                          <SessionsDropDown />
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label>Select Wing:</label>
                        <select
                          name="WingId"
                          onChange={(e) => setpromoteWingID(e.target.value)}
                          className="form-select"
                          required
                        >
                          <option value="">---All---</option>
                          <WingsDropDown />
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label className="">
                          Select Class:
                        </label>
                        <select
                          className="form-select"
                          onChange={(e) => setPromoteClassID(e.target.value)}
                          required
                        >
                          <option value="">Please Select</option>
                          {promoteSessionID != 0 && promoteWingID != 0 && (
                            <ClassesDropDown
                              SessionID={promoteSessionID}
                              WingID={promoteWingID}
                            />
                          )}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label className="">
                          Select Section:
                        </label>
                        <select
                          className="form-select"
                          onChange={(e) => setPromoteSectionID(e.target.value)}
                          required
                        >
                          <option value="">Please Select</option>
                          {promoteClassID !== 0 && (
                            <SectionDropDown ClassID={promoteClassID} />
                          )}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12 text-end">
                      <div className="form-group text-end">
                        <input
                          type="submit"
                          value="Promote Students"
                          className="button button-primary"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchStudents;
