import ReportsMenu from "./ReportsMenu";
import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import SessionsDropDown from "../Shared/SessionsDropDown";
import WingsDropDown from "../Shared/WingsDropDown";
import ClassesDropDown from "../Shared/ClassesDropDown";
import SectionDropDown from "../Shared/SectionDropDown";
import StudentGroupDropDown from "../Shared/StudentGroupDropDown";
import StudentStatusDropDown from "../Shared/StudentStatusDropDown";
import { toast, ToastContainer } from "react-toastify";
import { NavLink, Link } from "react-router-dom";
import moment from "moment";
import Pagination from "../General/Pagination";
import Dropdown from "react-bootstrap/Dropdown";
import { Form, PageItem } from "react-bootstrap";
import MonthsDropDown from "../Shared/MonthsDropDown";
import YearsDropDown from "../Shared/YearsDropDown";
import { calculateColumnTotal, getMonthName } from "../General/Utility";
import { scrollToTop } from "../Shared/ScrollOnTop";
import csvDownload from "json-to-csv-export";

const RemarksConcessionReport = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  //const [DefaultSession, setDefaultSession] = useState(localStorage.getItem("DefaultSession"));

  const [showFilters, setShowFilters] = useState(true);

  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [validated, setValidated] = useState(false);
  const [listOfLedgerforcvs, setLedgerforcvs] = useState([]);
  const [loaddata, setLoaddata] = useState(false);
  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const [Wing, setWing] = useState("");
  const [Section, setSection] = useState("");
  const [SessionID, setSessionID] = useState("");
  const [Session, setSession] = useState(
    localStorage.getItem("DefaultSession")
  );
  const [Class, setClass] = useState("");
  const [ReportMonth, setReportMonth] = useState(moment().format("M"));
  const [ReportYear, setReportYear] = useState(moment().format("YYYY"));

  document.title = "Remarks Concession Report";

  // for filters

  useEffect(() => {
    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: pageSize,
      ReportYear: ReportYear,
      ReportMonth: ReportMonth,
      Wing_Id: Wing,
      Class_Id: Class,
      Section_Id: Section,
    };
    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "/Reports/GetRemarksConcessionReports",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    // console.log(data);

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofRecords);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [pageNo, pageSize, ReportYear, ReportMonth, Wing, Class, Section]);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setReportYear(event.target.ReportYear.value);
      setReportMonth(event.target.ReportMonth.value);
      setWing(event.target.Wing.value);
      setClass(event.target.Class.value);
      setSection(event.target.Section.value);
    }

    setValidated(true);
  };

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const handleSubmitForCvs = (event) => {
    //console.log(event);

    setLoaddata(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: 10000,
      ReportYear: ReportYear,
      ReportMonth: ReportMonth,
      Wing_Id: Wing,
      Class_Id: Class,
      Section_Id: Section,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "/Reports/GetRemarksConcessionReports",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          if (response.data.totalRecords > 0) {
            setLedgerforcvs(response.data.ListofRecords);
            let data = response.data.ListofRecords;

            const listOfLedgerNew = data.map(({ ...rest }) => ({
              Registration_No: rest.Registration_No,
              FeeCode: rest.Student_Name,
              ConcessionGroup: rest.Father_CNIC + "-" + rest.Contact_Email,
              NoOfStudents: rest.Concession_Remarks,
              Amount: rest.Concession_Amount,
            }));
            console.log(listOfLedgerNew);

            const dataToConvert = {
              data: listOfLedgerNew,
              filename: "RemarksConcessionReport",
              delimiter: ",",
              headers: [
                "Registration No",
                "Student Name",
                "Class",
                "Concession Remarks",
                "Concession Amount",
              ],
            };
            csvDownload(dataToConvert);

            setLoaddata(false);
          } else if (response.data.totalRecords == 0) {
            toast.error("There is no data available for download", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoaddata(false);
          }
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoaddata(false);
        }
      })
      .catch(function (error) {
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoaddata(false);
      });
  };

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.Registration_No}</td>
            <td>{item.Student_Name}</td>
            <td>
              {item.Father_CNIC} - {item.Contact_Email}
            </td>
            <td>{item.Concession_Remarks}</td>
            <td>{item.Concession_Amount}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="12">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };
  return (
    <>
      <ReportsMenu />
      <ToastContainer />
      <div className="page-head">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/Dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/Reports/ReportsDashboard">Reports</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Remarks Concession Report
                  </li>
                </ol>
              </nav>
              <div className="page-title">Remarks Concession Report</div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`collapse ${showFilters ? "show" : ""} `}
        id="collapseFilteres"
      >
        <div className="page-content">
          <div className="container">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div className="card mb-2">
                <div className="card-body p-md-4">
                  <div className="row align-items-center">
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label className="">Select Session:</label>
                        <select
                          className="form-select"
                          name="Session"
                          onChange={(e) => setSession(e.target.value)}
                        >
                          <option value="0">---All---</option>
                          <SessionsDropDown selectedSession={Session} />
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label>Select Wing:</label>
                        <select
                          name="Wing"
                          className="form-select"
                          onChange={(e) => setWing(e.target.value)}
                        >
                          <option value="">---All---</option>
                          <WingsDropDown />
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label className="">Select Class:</label>

                        <select
                          className="form-select"
                          required
                          name="Class"
                          onChange={(e) => setClass(e.target.value)}
                        >
                          <option value="">Please Select</option>
                          {Session != 0 && Wing != 0 && (
                            <ClassesDropDown
                              SessionID={Session}
                              WingID={Wing}
                            />
                          )}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label className="">Select Section:</label>
                        <select
                          className="form-select"
                          name="Section"
                          required
                          onChange={(e) => setSection(e.target.value)}
                        >
                          <option value="">Please Select</option>
                          {Class != 0 && <SectionDropDown ClassID={Class} />}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="">Select Month:</label>
                        <select
                          name="ReportMonth"
                          defaultValue={ReportMonth}
                          onChange={(e) => setReportMonth(e.target.value)}
                          className="form-select"
                        >
                          <option value="">Please Select</option>
                          <MonthsDropDown />
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="">Select Year:</label>
                        <select
                          name="ReportYear"
                          onChange={(e) => setReportYear(e.target.value)}
                          className="form-select"
                        >
                          <option value="">Please Select</option>
                          <YearsDropDown currentYearSelectd={ReportYear} />
                        </select>
                      </div>
                    </div>
                    {loaddata ? (
                      <div className="col-md-2 offset-2 text-center mt-3">
                        <div>
                          <div>
                            <Spinner animation="border" role="status"></Spinner>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="col-md-2 offset-2 text-end ">
                        <div className="form-group text-end mt-4">
                          <a
                            type="button"
                            onClick={handleSubmitForCvs}
                            className="print-icon"
                          >
                            <span>
                              <i className="ri-file-excel-2-line"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                    )}

                    <div className="col-md-2 text-end">
                      <div className="form-group text-end">
                        <button
                          type="submit"
                          value="Search"
                          className="w-100 button button-primary mt-4"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="container">
          <div className="card">
            <div className="card-body">
              <table className="table table-theme">
                <thead>
                  <tr>
                    <th colSpan={7}>Total Records Found: {totalRecords}</th>
                    <th className="text-end">
                      {/* Total Amount : {calculateColumnTotal(listOfLedger, 'Opening_Balance')} */}
                    </th>
                  </tr>
                </thead>
                <thead>
                  <tr>
                    <th>Registration No</th>
                    <th>Student Name</th>
                    <th>Class</th>
                    <th>Concession Remarks</th>
                    <th>Concession Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td className="text-center" colSpan="12">
                        <Spinner animation="border" role="status"></Spinner>
                      </td>
                    </tr>
                  ) : (
                    <>{renderLedger()}</>
                  )}
                </tbody>
              </table>
              {totalRecords > 10 && (
                <div className="pagination-wrap">
                  <div className="row align-items-center">
                    <div className="col-md-2 form_default">
                      <div className="d-flex align-items-center">
                        <label className="me-2" style={{ fontSize: "15px" }}>
                          Page Size
                        </label>
                        <div className="form-group">
                          <select
                            className="form-select"
                            style={{ width: "100px" }}
                            onChange={(e) => {
                              setPageNo(1);
                              setPageSize(parseInt(e.target.value));
                            }}
                          >
                            <option value="10" selected>
                              10
                            </option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <Pagination
                      pageNo={pageNo}
                      pageSize={pageSize}
                      totalRecords={totalRecords}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="card mt-2">
            <div className="card-body p-md-4">
              <div className="row">
                <div className="col-md-12 text-end">
                  <NavLink
                    className="button button-primary"
                    to={`/Reports/RemarksConcessionPrint?ReportYear=${ReportYear}&ReportMonth=${ReportMonth}&Wing_Id=${Wing}&Class_Id=${Class}&Section_Id=${Section}&Class_Id=${Class}
                                       `}
                    target="_blank"
                  >
                    Print Report
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RemarksConcessionReport;
