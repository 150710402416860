import React, { useState, useEffect, useCallback } from "react";
import FeeSystemMenu from "./FeeSystemMenu";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { toast, ToastContainer } from "react-toastify";
import { Form } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import moment from "moment";

function ImportFee() {
    const [AccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID] = useState(localStorage.getItem("ID"));
    const [MemberType] = useState(localStorage.getItem("MemberType"));
    const [SessionID] = useState(localStorage.getItem("DefaultSession"));
    const [User_Name, setUser_Name] = useState(localStorage.getItem("UserName"));
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [validated, setValidated] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Import Fee";
    }, []);

    const fileTypes = [
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "text/csv",
    ];

    const validateFile = useCallback(() => {
        if (!file) {
            toast.error("Please select a file to upload", {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }

        if (!fileTypes.includes(file.type)) {
            toast.error("Please select only Excel or CSV file types", {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }

        return true;
    }, [file, fileTypes]);

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file); // Read file as Data URL
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (!validateFile()) return;

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            return;
        }

        setLoading(true);

        try {
            const base64File = await convertToBase64(file);

            const fileName = file.name;

            const formData = {
                accessKey: AccessKey,
                userId: UserID,
                fileContent: base64File,
                fileName: fileName,
                Class_ID: moment().format("M"),
                Section_Id: moment().format("YYYY"),
                Vaccination_Status: User_Name,
            };

            var api_config = {
                method: "post",
                url: config.base_url + "FeeSystem/ImportFee",
                headers: {
                    "Content-Type": "application/json",
                },
                data: JSON.stringify(formData), // Send JSON data
            };
            console.log(formData);

            axios(api_config)
                .then((response) => {
                    if (response.data.status_code === 1) {
                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        // navigate("/Fee/SearchFee");
                    } else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    ////console.error(error);
                    toast.error("Network Error..", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                });

            setValidated(true);
        } catch (error) {
            setLoading(false);
            // ////console.error("Error converting file to Base64", error);
            toast.error("File conversion error.", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    return (
        <>
            <FeeSystemMenu />
            <ToastContainer />
            <div className="container body-content">
                <div className="page-head">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <NavLink to="/Dashboard">Dashboard</NavLink>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <NavLink to="/FeeSystem/FeeSystemDashboard">
                                                Fee System
                                            </NavLink>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Import Fee
                                        </li>
                                    </ol>
                                </nav>
                                <div className="page-title">Import Fee</div>
                            </div>
                            <div className="col-md-6">
                                <div className="page-right-actions">
                                    <NavLink
                                        to="/FeeSystem/PrintVouchers"
                                        className="button button-white button-icon"
                                    >
                                        Print Vouchers<i className="ri-pages-line"></i>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body card-form-wrap">
                                        <Form
                                            noValidate
                                            validated={validated}
                                            onSubmit={handleSubmit}
                                        >
                                            <div className="form-wrap">
                                                <div className="form-legend">Import Fee</div>
                                                <div className="row align-items-center">
                                                    <div className="col-md-6">
                                                        <h5>Import CSV List of Fee</h5>
                                                        <p>
                                                            Please make sure that your file follows the
                                                            template that can be downloaded from the link
                                                            below.
                                                        </p>
                                                        <a
                                                            className="mt-2 button button-primary w-50"
                                                            href="https://agsapi.jueducation.com/SampleForImportFee.xlsx" download
                                                        >
                                                            DOWNLOAD TEMPLATE
                                                        </a>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="formFile" className="form-label">
                                                                Import File Here
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                type="file"
                                                                onChange={(e) => setFile(e.target.files[0])}
                                                                id="formFile"
                                                                accept=".xls,.xlsx,.csv"
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-3 offset-md-9 text-md-end">
                                                        <div className="form-group">
                                                            {loading ? (
                                                                <Spinner animation="border" role="status" />
                                                            ) : (
                                                                <button
                                                                    type="submit"
                                                                    className="mt-2 button button-primary w-100"
                                                                >
                                                                    IMPORT FEE
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ImportFee;