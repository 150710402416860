import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import QRCode from "react-qr-code";
import StudentInfo from "../FeeSystem/StudentInfo";
import moment from "moment";
import PrintHeader from "../General/PrintHeader";
import Page from "../General/Page";
import { calculateColumnTotal, getMonthName } from "../General/Utility";
const RoutesPrint = () => {
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(
        localStorage.getItem("MemberType")
    );
    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
    const [ReportMonth, setReportMonth] = useState("");
    const [ReportYear, setReportYear] = useState("");
    const [Wing, setWing] = useState("");
    const [Section, setSection] = useState("");
    const [SessionID, setSessionID] = useState("");
    const [Session, setSession] = useState("");
    const [Class, setClass] = useState("");
    const [RouteID, setRouteID] = useState("");

    // pagination
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [TotalAmount, setTotalAmount] = useState(0);

    useEffect(() => {
        document.title = "AGS School Management System";

        setLoading(true);
        // Get the URL parameters
        const urlParams = new URLSearchParams(window.location.search);

        // Retrieve individual parameter values
        const ReportYear = urlParams.get("ReportYear");
        const ReportMonth = urlParams.get("ReportMonth");
        const Wing_Id = urlParams.get("Wing");
        const Class_Id = urlParams.get("Class");
        const Section_Id = urlParams.get("Section");
        const Route_Id = urlParams.get("RouteID");

        setReportYear(ReportYear);
        setReportMonth(ReportMonth);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            pageNo: 1,
            pageSize: 10000,
            ReportYear: ReportYear,
            ReportMonth: ReportMonth,
            Wing_Id: Wing,
            Class_Id: Class,
            Section_Id: Section,
            AccountLevel: RouteID,
        };

        //console.log(data);

        var api_config = {
            method: "post",
            url: config.base_url + "/Reports/GetRoutesReport",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        //console.log(data);
        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setLedger(response.data.ListofRecords);
                setLoading(false);
                setTotalRecords(response.data.totalRecords);
                setTotalAmount(response.data.TotalAmount);
            })
            .catch(function (error) {
                //console.log(error);
            });
    }, []);

    const renderLedger = () => {
        if (listOfLedger != null && listOfLedger.length > 0) {
            return listOfLedger.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{item.BusRoute_Code}</td>
                        <td>{item.NoofStudents}</td>
                        <td>{item.Fee_Posted}</td>
                        <td>{item.ConcessionAmount}</td>
                        <td>{item.ReceiveableFee}</td>
                        <td>{item.ReceivedAmount}</td>
                        <td>{item.PendingFee}</td>
                    </tr>
                );
            });
        } else {
            return (
                <tr>
                    <td colSpan="6">
                        <h4
                            className="mt-3"
                            style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
                        >
                            No Data Found
                        </h4>
                    </td>
                </tr>
            );
        }
    };

    return (
        <>
            <Page orientation="portrait" size="a4">
                <table className="table table-sm table-bordered table-print">
                    <thead>
                        <tr>
                            <th colSpan={7}>
                                <PrintHeader
                                    title={`Routes Report for the month of ${getMonthName(
                                        ReportMonth
                                    )} ${ReportYear} `}
                                />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td className="text-center" colSpan="6">
                                    <Spinner animation="border" role="status"></Spinner>
                                </td>
                            </tr>
                        ) : (
                            <>
                                <tr>
                                    <th>Route Code</th>
                                    <th>No Of Students</th>
                                    <th>Fee Posted</th>
                                    <th>Concession Amount</th>
                                    <th>Receiveable Fee</th>
                                    <th>Received Amount</th>
                                    <th>Pedning Fee</th>
                                </tr>
                                {renderLedger()}
                                <tr>
                                    <th colSpan={2}>Total Amount :</th>
                                    <th className="">
                                        {calculateColumnTotal(listOfLedger, "Fee_Posted")}
                                    </th>
                                    <th className="">
                                        {calculateColumnTotal(listOfLedger, "ConcessionAmount")}
                                    </th>
                                    <th className="">
                                        {calculateColumnTotal(listOfLedger, "ReceiveableFee")}
                                    </th>
                                    <th className="">
                                        {calculateColumnTotal(listOfLedger, "ReceivedAmount")}
                                    </th>
                                    <th className="">
                                        {calculateColumnTotal(listOfLedger, "PendingFee")}
                                    </th>
                                </tr>
                            </>
                        )}
                    </tbody>
                </table>
            </Page>
        </>
    );
};

export default RoutesPrint;